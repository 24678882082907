import {Container} from 'react-bootstrap';
import {Link, Outlet, useNavigate} from 'react-router-dom';
import {useEffect} from "react";

const MainDashBoard = ({sections}) => {
  // const listItems = sections.map(item => (
  //   <Link to={`/${item.route}`} className="section-item" key={item.id}>
  //       {item.section}
  //   </Link>
  // ));

  const navigate = useNavigate();
  useEffect(() => (
    navigate("/login")
  ), []);

  return (
    <main>
      <Container fluid className="mainDashBoard">
        {/*<div className="sections">*/}
        {/*  {listItems}*/}
        {/*</div>*/}
      </Container>
    </main>
  )
};

export default MainDashBoard;