import './App.css';
import React from "react";
import {Route, Routes} from 'react-router-dom';
import AuthenticationForm from "./Components/AuthenticationForm";
import OrdersDashBoard from "./Components/OrdersDashBoard";
import NotFoundPage from "./Components/NotFoundPage";
import PrivateRoutes from "./utils/PrivateRoutes";
import NotLoggedIn from "./Components/NotLoggedIn";
import OrderDetails from "./Components/OrderDetails";
import MainDashBoard from "./Components/MainDashboard";

function App() {

  return (
    <div className="app">
      <Routes>
        <Route element={<PrivateRoutes/>}>
          <Route path="/:agentName" element={<OrdersDashBoard />}/>
          <Route path="/order/:orderId" element={<OrderDetails/>}/>
          <Route path="*" element={<NotFoundPage/>}/>
        </Route>
        <Route path="/" element={<MainDashBoard />} />
        <Route path="login" element={<AuthenticationForm/>}/>
        <Route path="notlogged" element={<NotLoggedIn/>}/>
      </Routes>
    </div>
  );
}

export default App;
