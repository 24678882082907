import {Container, ListGroup, Nav, Navbar, NavDropdown} from 'react-bootstrap';
import {Link, useNavigate } from 'react-router-dom';
import {logout} from "../store/authSlice";
import {useDispatch, useSelector} from "react-redux";
import logo from "../logo1.png";

const NavBar = () => {
  const dispatch = useDispatch();
  const agentName = useSelector(state => state.auth.agentName);
  const agent = useSelector(state => state.auth.agent);
  const navigate = useNavigate();

  const handleGoBack = () => navigate(-1);

  const handleLogout = () => {
    localStorage.clear();
    dispatch(logout());
  };

  return (
    <Navbar expand="sm" className="bg-body-tertiary">
      <Navbar.Toggle aria-controls="basic-navbar-nav"/>
      <Navbar.Brand as={Link} to={`/${agent}`}>
        <img
          src={logo}
          width="27"
          height="27"
          alt="alesta logo"
        />
      </Navbar.Brand>
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ms-auto">
          <Nav.Item className="agent-name">
            {agentName}
            <i className="bi bi-person-fill"></i>
          </Nav.Item>
          <Nav.Link onClick={handleGoBack}>Назад</Nav.Link>
          <Nav.Link as={Link} to={`/${agent}`}>Заказы</Nav.Link>
          <Nav.Link as={Link} to="/login" onClick={handleLogout}>Выход</Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  )
};

export default NavBar;