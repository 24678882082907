import {Table} from "react-bootstrap";
import {nanoid} from '@reduxjs/toolkit';
import {useSortableData} from "../utils/sortData";

const AppTable = ({data, headers}) => {
  const {items, requestSort, sortConfig} = useSortableData(data);

  let keys = items.reduce((keysArr, keysObj) => [...keysArr, ...Object.keys(keysObj)], []);

  const tableHeaders = headers.map((item, i) => <th key={nanoid()} onClick={() => requestSort(keys[i])}>{item}</th>);
  const calc = data.reduce((acc, cur) => {
    if ("checkSum" in cur) {
      return acc + cur.checkSum;
    }
  }, 0);
  const tableContent = items.map((item) => (
    <tr key={nanoid()}>
      {Object.values(item).map(val => <td key={nanoid()}>{val}</td>)}
    </tr>
  ));

  return (
    <Table bordered hover className="info-table">
      <thead>
      <tr>
        {tableHeaders}
      </tr>
      </thead>
      <tbody>
      {tableContent}
      {calc ?
        <tr>
          <td colSpan={2}>итого</td>
          <td>{(calc).toFixed(2)}</td>
          <td>{items[0].checkCurrency}</td>
          <td></td>
        </tr>
        :
        ''
      }
      </tbody>
    </Table>
  )
};

export default AppTable;
