import {Container} from "react-bootstrap";
import {Link} from "react-router-dom";

const NotFoundPage = () => {
  return (
    <Container className="errorPage" fluid>
      <div>
        <h1>404</h1>
        <h2>запрашиваемая страница не найдена</h2>
        <Link to="/">Перейти на главную</Link>
      </div>
    </Container>
  )
};

export default NotFoundPage;