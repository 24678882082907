import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter, HashRouter } from "react-router-dom";
import './index.css';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Provider} from "react-redux";
import {store} from "./store/index";
import 'bootstrap-icons/font/bootstrap-icons.css';
import "./fonts/Roboto-Regular.ttf";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <App/>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);
