import Spinner from 'react-bootstrap/Spinner';
import {Col} from 'react-bootstrap';

function Loading({className}) {

  return (
    <div className={className}>
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>
  );
}

export default Loading;