import { Form, InputGroup } from 'react-bootstrap';
import { useState } from "react";

const SearchBar = ({ handleSearch, searchQuery }) => {
  const handleChange = (event) => {
    handleSearch(event.target.value);
  };

  return (
    <>
      <InputGroup className="mb-3">
        <Form.Control
          placeholder="поиск..."
          aria-label="search order"
          value={searchQuery} // Make it a controlled component
          onChange={handleChange} // Call handleSearch with the new value
        />
        <InputGroup.Text>
          <i className="bi bi-search"></i>
        </InputGroup.Text>
      </InputGroup>
    </>
  );
};

export default SearchBar;
