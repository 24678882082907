import {Container, Row, Col} from "react-bootstrap";
import {useState, useEffect} from "react";
import {useParams} from "react-router-dom";
import {useGetRegistrationInfoQuery, useGetServicesQuery} from "../store/apiSlice";
import Header from "./Header";
import Loading from "./Spinner";
import AppTable from "./AppTable";

const OrderDetails = () => {

  const routeParams = useParams();
  const [showInfoCard, setShowInfoCard] = useState(false);

  const {
    data: registration,
    isLoading: isRegistrationLoading,
    isSuccess: isRegistrationSuccess
  } = useGetRegistrationInfoQuery(routeParams.orderId);
  const {
    data: services,
    isLoading: isServicesLoading,
    isSuccess: isServicesSuccess
  } = useGetServicesQuery(routeParams.orderId);

  useEffect(() => {
    const delay = setTimeout(() => {
      setShowInfoCard(true);
    }, 100); // Время задержки
    return () => clearTimeout(delay);
  }, []);

  return (
    <>
      <Header/>
      <main>
        <Container fluid className="order-details">
          {/* Показывать спиннер, пока грузятся данные */}
          {(isRegistrationLoading || isServicesLoading) ? <Loading className={"spinner"}/>
            :
            <>
              <Row>
                <Col>
                  <h2>детали заказа</h2>
                </Col>
              </Row>
              <Row>
                {/* Рендерим таблицу с регистрацией, если есть данные */}
                {isRegistrationSuccess && registration.length > 0 ? (
                    <Col className={`col-12 table-responsive info-card ${showInfoCard ? 'show' : ''}`}>
                      <h4>информация о регистрации</h4>
                      <AppTable
                        data={registration}
                        headers={[
                          "номер авто",
                          "номер документа",
                          "тип документа",
                          "разрешение",
                          "CMR",
                          "статус",
                          "подследнее изменение"
                        ]}
                      />
                    </Col>
                  )
                  :
                  ""
                }
                {/* Рендерим таблицу с услугами, если есть данные */}
                {isServicesSuccess && services.length > 0 ? (
                    <Col className={`col-12 table-responsive info-card ${showInfoCard ? 'show' : ''}`}>
                      <h4>информация о предоставленных услугах</h4>
                      <AppTable
                        data={services}
                        headers={[
                          "услуга",
                          "количество",
                          "сумма по счету",
                          "валюта счета",
                          "примечание"
                        ]}
                      />
                    </Col>
                  )
                  :
                  <div className="not-ready">
                    <p>заказ находится в работе</p>
                  </div>
                }
              </Row>
            </>
          }
        </Container>
      </main>
    </>
  );
};

export default OrderDetails;

