import {createSlice} from "@reduxjs/toolkit";

const authSlice = createSlice({
  name: 'token',
  initialState: {
   // accessToken: localStorage.getItem('accessToken') || '',
    agent: null,
    accessToken: null,
    agentName: null
  },
  reducers: {
    setTokens: (state, action) => {
      state.accessToken = action.payload.token;
      state.agent = action.payload.agent;
      state.agentName = action.payload.agentName;
    },
    logout: (state) => {
      state.accessToken = null;
      state.agent = null;
      state.agentName = null;
    },
  },
});

export const { setTokens, logout } = authSlice.actions;
export default authSlice.reducer;
