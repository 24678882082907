import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import {setTokens, logout} from "./authSlice";
import { reverseDate } from "../utils/reverseDate";

const baseUrl = 'https://clients.alesta.group/api/v1/';

const baseQuery = fetchBaseQuery({
  baseUrl,
  //credentials: 'include',
  prepareHeaders: (headers, {getState}) => {
    // getting access token from redux store
    const token = getState().auth.accessToken || localStorage.getItem('accessToken');
    if (token) {
      headers.set('Content-type', 'application/json');
      headers.set('Authorization', `Bearer ${token}`);
    }
    return headers
  }
});

// custom baseQuery with reauthentication
const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result?.error?.status === 401) {
    // send refresh token to get new access token
    const refreshResult = await baseQuery(
      {
        url: "token/refresh/",
        method: "POST",
        body: JSON.stringify({"refresh" : localStorage.getItem("refreshToken")})
      },
      api,
      extraOptions
    );

    if (refreshResult?.data) {
      // store the new token
      api.dispatch(setTokens(refreshResult.data));
      // retry the original query with new access token
      result = await baseQuery(args, api, extraOptions)
    } else {
      api.dispatch(logout());
    }
  }

  return result
};

export const apiSlice = createApi({
  reducerPath: 'apiSlice',
  baseQuery,
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (body) => ({
        url: 'auth/login',
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        body,
      }),
    }),
    getOrdersByAgent: builder.query({
      query: (paramQuery) => ({
        url: `zakaz/agent/${paramQuery}`
      }),
    }),
    getRegistrationInfo: builder.query({
      query: (id) => ({
        url: `registration/order/${id}`
      }),
      transformResponse: response => {
        const regInfo = response.map(item =>({
          truckNumber: item.regNumberAvto,
          epiNumber: item.numbEPI,
          typeDoc: item.typeDoc === 0 ? item.typeDoc = "ЭПИ" : item.typeDoc === 1 ? item.typeDoc = "ТД" : item.typeDoc = "сертификат обеспечения",
          permission: item.permission,
          cmrNumber: isNaN(Number(item.regNumberDoc)) ? item.regNumberDoc : Number(item.regNumberDoc),
          status: item.status,
          lastChange: reverseDate(item.lastChange),
        }));
        return regInfo;
      },
    }),
    getServices: builder.query({
      query: (id) => ({
        url: `service/order/${id}`
      }),
      transformResponse: response => {
        const serviceInfo = response.map(item =>({
          serviceName: item.priceUslugi.uslugi.serviceName,
          insuranceSum: item.quantity,
          checkSum: item.sum,
          checkCurrency: item.schet.valuta,
          note: item.note
        }));
        return serviceInfo;
      },
    }),
    getSearch: builder.query({
      query: (searchQuery) => ({
        url: `zakaz/search${searchQuery}`
      }),
    })
  })
});

export const {
  useLoginMutation,
  useGetOrdersByAgentQuery,
  useGetRegistrationInfoQuery,
  useGetServicesQuery,
  useGetSearchQuery
} = apiSlice;
