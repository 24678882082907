import {Container} from "react-bootstrap";
import {Link} from "react-router-dom";

const NotLoggedIn = () => {
  return (
    <Container className="errorPage" fluid>
      <div>
        <h1>упс...</h1>
        <h2>Пользователь с такими учетными данными не найден</h2>
        <Link to="/login">на страницу авторизации</Link>
      </div>
    </Container>
  )
};

export default NotLoggedIn;