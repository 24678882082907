import {useNavigate} from "react-router-dom";
import { nanoid } from '@reduxjs/toolkit';
import { reverseDate } from "../utils/reverseDate";

const TableItems = ({data}) => {

  const navigate = useNavigate();
  const goRouteId = (id) => {
    navigate(id, {state: {data: data}})
  };

  const ordersList = data.map((item, i) => (
    <tr key={nanoid()} onClick={() => goRouteId(`/order/${item.idzakaz}`)}>
      <td>{reverseDate(item.lastChangeDate)}</td>
      <td>{item.carrier}</td>
      <td>{item.avtoNumber}</td>
      <td>{item.driver}</td>
      <td>{item.statusOrder}</td>
    </tr>
  ));

  return (
    <>
      {ordersList}
    </>
  )
};

export default TableItems;
