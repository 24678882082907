import {useMemo} from 'react';
import Pagination from 'react-bootstrap/Pagination';

const PaginationComponent = ({data, paginate, currentPage, dataPerPage}) => {
  const pageNumbers = useMemo(() => {
    const allPages = data.totalPages || 0;
    return new Array(allPages).fill(0).map((_, idx) => idx + 1);
  }, [data.totalPages, dataPerPage]);

  let maxButtonsToShow = 2; // Maximum number of buttons to show excluding first and last

  let start = Math.max(1, currentPage - Math.floor(maxButtonsToShow / 2));
  let end = Math.min(pageNumbers.length, start + maxButtonsToShow + 1);

  if (end === pageNumbers.length && start > 1) {
    start = Math.max(1, end - maxButtonsToShow - 1);
  } else if (start === 1 && end < pageNumbers.length) {
    end = Math.min(pageNumbers.length, start + maxButtonsToShow );
  }

  const screen = window.innerWidth;

  return (
      <Pagination>
        <Pagination.First onClick={() => paginate(0)} disabled={currentPage === 0}/>
        <Pagination.Prev onClick={() => paginate(currentPage - 1)} disabled={currentPage === 0}/>
        {start >= 2 && <Pagination.Item onClick={() => paginate(0)}>1</Pagination.Item>}
        {start > 2 && start >= 3 && <Pagination.Ellipsis/>}
        {pageNumbers.slice(start - 1, end + 1).map((number) => (
          <Pagination.Item key={number} onClick={() => paginate(number - 1)} active={currentPage === number - 1}>
            {number}
          </Pagination.Item>
        ))}
        {end < pageNumbers.length - 1 && end <= pageNumbers.length - 2 && <Pagination.Ellipsis/>}
        {end !== pageNumbers.length && (
          <Pagination.Item onClick={() => paginate(pageNumbers.length - 1)}>{pageNumbers.length}</Pagination.Item>
        )}
        <Pagination.Next onClick={() => paginate(currentPage + 1)} disabled={currentPage === pageNumbers.length - 1}/>
        <Pagination.Last onClick={() => paginate(pageNumbers.length - 1)}
                         disabled={currentPage === pageNumbers.length - 1}/>
      </Pagination>
  )
};

export default PaginationComponent;
