import {Outlet, Navigate} from 'react-router-dom';
import OrdersDashBoard from "../Components/OrdersDashBoard";
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setTokens } from '../store/authSlice';

const PrivateRoutes = () => {
  const auth = useSelector(state => state.auth);
  const dispatch = useDispatch();

  // Initialize authentication state on app load
  useEffect(() => {
    const accessToken = localStorage.getItem('accessToken');
    const agent = localStorage.getItem('agent');
    const agentName = localStorage.getItem('agentName');
    if (accessToken) {
      // Dispatch action to set authentication state
      dispatch(setTokens({ token: accessToken, agent, agentName }));
    }
  }, [dispatch]);

  return (
    auth ? <Outlet/> : <Navigate to="/login" />
  )
};

export default PrivateRoutes;
