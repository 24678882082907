import {Row, Col, ListGroup} from 'react-bootstrap';
import {CircleFlag} from 'react-circle-flags';
import NavBar from "./NavBar";

const Header = () => {
  return (
    <header>
      <Row>
        <Col className="header">
          <NavBar/>
          <ListGroup horizontal="sm" className="contacts">
            <ListGroup.Item>
              <div className="contacts --our-contacts">
                НАШИ КОНТАКТЫ:
              </div>
            </ListGroup.Item>
            <ListGroup.Item>
              <i className="bi bi-envelope"></i>
              <a href="mailto:mail@alesta.group">
                mail@alesta.group
              </a>
            </ListGroup.Item>
            <ListGroup.Item>
              <i className="bi bi-telephone"></i>
              <span className="image-container">
               <CircleFlag countryCode="by" alt="BY"/>
              </span>
              <a href="tel:+375336262122">+375 33 62 62 122</a>
            </ListGroup.Item>
            <ListGroup.Item>
              <i className="bi bi-telephone"></i>
              <span className="image-container">
                <CircleFlag countryCode="lt" alt="LT"/>
              </span>
              <a href="tel: +37061618111">+370 616 18 111</a>
            </ListGroup.Item>
            <ListGroup.Item>
              <i className="bi bi-telephone"></i>
              <span className="image-container">
                <CircleFlag countryCode="ru" alt="RU"/>
              </span>
              <a href="tel:+79585815149">+792 407 792 15</a>
            </ListGroup.Item>
          </ListGroup>
        </Col>
      </Row>
    </header>
  )
};

export default Header;